<template>
    <!--
    <div class="navi">
        <div class="navi-box wrap-sm">
            <a href="#">HOME</a>
            <a href="#">경기인 정보</a>
            <span v-if="activeTabId == 1">시.도선수</span>
            <span v-if="activeTabId == 2">지도자</span>
            <span v-if="activeTabId == 3">심판</span>
        </div>
    </div>
    -->
    <div class="subtop">
		<div class="subtop-cont">
			<div class="subtop-title title-2">
				<p>경기인 정보</p>
				<span>최신 경기인 선수의 정보를 확인 하세요.</span>
			</div>
		</div>
		<img class="subtop-img" src="@/assets/images/img/subtop-1.png">
	</div>
    <!--
    <div class="sub-menu">
		<ul class="float-wrap">
			<li class="on"><router-link to="/about-greeting">시/도선수</router-link></li>
			<li><router-link to="/about-ksa">지도자</router-link></li>
			<li><router-link to="/about-organization">심판</router-link></li>
		</ul>
	</div>
    -->
    <div class="sub-wrap sub-wrap-grey" style="padding-top: 15px;">
        <div class="sub-cont">
            <div class="tab-container tab-box">
                <div class="tab tab-4">
                    <button type="button" :class="{ 'on': tabs === 'leader' }" @click="tabs = 'leader'">지도자</button>
                    <button type="button" :class="{ 'on': tabs === 'supLeader' }" @click="tabs = 'supLeader'">SUP지도자</button>
                    <button type="button" :class="{ 'on': tabs === 'rescue' }" @click="tabs = 'rescue'">서프레스큐</button>
                    <button type="button" :class="{ 'on': tabs === 'referee' }" @click="tabs = 'referee'">심판</button>
                    <!-- <button type="button" :class="{ 'on': tabs === 'player' }" @click="tabs = 'player'"></button> -->
                </div>
                <div class="tab-contents">

                    <!-- 지도자 -->
                    <div class="tab-cont" :class="{ 'active': tabs === 'leader' }">
                        <p class="cont-total">인원 <span>51</span> 명</p>
                        <ul class="profiles float-wrap">
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/kimjinik.jpg"></div>
                                <div class="profile-cont">
                                    <p>김진익 KIM JINIK</p>
                                    <ul>
                                        <li>level 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/parkbyungjoo.jpg"></div>
                                <div class="profile-cont">
                                    <p>박병주 PARK BYUNGJOO</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/leeyujun.jpg"></div>
                                <div class="profile-cont">
                                    <p>이유준 LEE YUJUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/jungminkyu.jpg"></div>
                                <div class="profile-cont">
                                    <p>정민규 JUNG MINKYU</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/jookwangchoun.jpg"></div>
                                <div class="profile-cont">
                                    <p>주광춘 JOO KWANGCHOUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/hongsuok.jpg"></div>
                                <div class="profile-cont">
                                    <p>홍수옥 HONG SUOK</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/kimdaekyoung.jpg"></div>
                                <div class="profile-cont">
                                    <p>김대경 KIM DAEKYOUNG</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/kimyonghun.jpg"></div>
                                <div class="profile-cont">
                                    <p>김용훈 KIM YONGHUN</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/kimjieun.jpg"></div>
                                <div class="profile-cont">
                                    <p>김지은 KIM JIEUN</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/kimjaeeun.jpeg"></div>
                                <div class="profile-cont">
                                    <p>김재은 KIM JAEEUN</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/kimheecheol.jpg"></div>
                                <div class="profile-cont">
                                    <p>김희철 KIM HEECHEOL</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/namsuah.jpg"></div>
                                <div class="profile-cont">
                                    <p>남수아 NAM SUAH</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/parkchulhwan.jpg"></div>
                                <div class="profile-cont">
                                    <p>박철환 PARK CHULHWAN</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/parkhyuntaek.jpg"></div>
                                <div class="profile-cont">
                                    <p>박현택 PARK HYUNTAEK</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/parkhyoeun.jpg"></div>
                                <div class="profile-cont">
                                    <p>박효은 PARK HYOEUN</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/baekseojun.jpg"></div>
                                <div class="profile-cont">
                                    <p>백서준 BAEK SEOJUN</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/yookyoungtae.jpg"></div>
                                <div class="profile-cont">
                                    <p>유경태 YOO KYOUNGTAE</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/changtonghun.jpg"></div>
                                <div class="profile-cont">
                                    <p>장동훈 CHANG TONGHUN</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/jobomyi.jpg"></div>
                                <div class="profile-cont">
                                    <p>조봄이 JO BOMYI</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/heoseunghoe.jpg"></div>
                                <div class="profile-cont">
                                    <p>허승회 HEO SEUNGHOE</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/hwanghyejin.jpg"></div>
                                <div class="profile-cont">
                                    <p>황혜진 HWANG HYEJIN</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/kimkeounghee.jpg"></div>
                                <div class="profile-cont">
                                    <p>김경희 KIM KEOUNGHEE</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/kimbyeonjae.jpg"></div>
                                <div class="profile-cont">
                                    <p>김병재 KIM BYEONGJAE</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/hadongwoo.jpg"></div>
                                <div class="profile-cont">
                                    <p>하동우 HA DONGWOO</p>
                                    <ul>
                                        <li>LEVEL 2</li> 
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/minkyungshik.jpeg"></div>
                                <div class="profile-cont">
                                    <p>민경식 MIN KYUNGSHIK</p>
                                    <ul>
                                        <li>LEVEL 3</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/songmink.jpg"></div>
                                <div class="profile-cont">
                                    <p>송 민 SONG MINK</p>
                                    <ul>
                                        <li>LEVEL 3</li>
                                    </ul>
                                </div>
                            </li>
                             <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level1-KANGHAYEON.jpg"></div>
                                <div class="profile-cont">
                                    <p>강하연 KANG HA YEON</p>
                                    <ul>
                                        <li>level 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level1-KIMGEUNWOO.jpg"></div>
                                <div class="profile-cont">
                                    <p>김건우 KIM GEUN WOO</p>
                                    <ul>
                                        <li>level 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level1-PARKJEUNGJONG.jpg"></div>
                                <div class="profile-cont">
                                    <p>박정종 PARK JEUNG JONG</p>
                                    <ul>
                                        <li>level 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level1-LEEJEONGHEE.jpg"></div>
                                <div class="profile-cont">
                                    <p>이정희 LEE JEONG HEE</p>
                                    <ul>
                                        <li>level 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level1-LIMSUJEONG.jpg"></div>
                                <div class="profile-cont">
                                    <p>임수정 LIM SU JEONG</p>
                                    <ul>
                                        <li>level 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level1-CHOIJAEYOUNG.jpg"></div>
                                <div class="profile-cont">
                                    <p>최재영 CHOI JAE YOUNG</p>
                                    <ul>
                                        <li>level 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level1-HANBYUNGCHAE.jpg"></div>
                                <div class="profile-cont">
                                    <p>한병채 HAN BYUNG CHAE</p>
                                    <ul>
                                        <li>level 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level1-KANGHYORIM.jpg"></div>
                                <div class="profile-cont">
                                    <p>강효림 KANG HYORIM</p>
                                    <ul>
                                        <li>level 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level1-KIMBYEUNGO.jpg"></div>
                                <div class="profile-cont">
                                    <p>김병오 KIM BYEUNG O</p>
                                    <ul>
                                        <li>level 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level1-KIMCHAEEUN.jpg"></div>
                                <div class="profile-cont">
                                    <p>김채은 KIM CHAE EUN</p>
                                    <ul>
                                        <li>level 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level2-KANGKYOUNGYUN.jpg"></div>
                                <div class="profile-cont">
                                    <p>강경연 KANG KYOUNG YUN</p>
                                    <ul>
                                        <li>level 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level1-KANGHAYEON.jpg"></div>
                                <div class="profile-cont">
                                    <p>강하연 KANG HAYEON</p>
                                    <ul>
                                        <li>level 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level2-KOKYOUNGJUN.jpg"></div>
                                <div class="profile-cont">
                                    <p>고경준 KO KYOUNG JUN</p>
                                    <ul>
                                        <li>level 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level2-KWONKYOUNGMIN.jpg"></div>
                                <div class="profile-cont">
                                    <p>권경민 KWON KYOUNG MIN</p>
                                    <ul>
                                        <li>level 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level2-KIMNAEUN.jpeg"></div>
                                <div class="profile-cont">
                                    <p>김나은 KIM NAEUN</p>
                                    <ul>
                                        <li>level 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level2-MUNSEOKJIN.jpg"></div>
                                <div class="profile-cont">
                                    <p>문석진 MUN SEOK JIN</p>
                                    <ul>
                                        <li>level 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level2-MOONJEONGHOON.jpg"></div>
                                <div class="profile-cont">
                                    <p>문정훈 MOON JEONG HOON</p>
                                    <ul>
                                        <li>level 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level2-PARKSUNGJUN.jpeg"></div>
                                <div class="profile-cont">
                                    <p>박성준 PARK SUNG JUN</p>
                                    <ul>
                                        <li>level 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level2-PARKWANWUNG.jpg"></div>
                                <div class="profile-cont">
                                    <p>박완웅 PARK WAN WUNG</p>
                                    <ul>
                                        <li>level 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level2-SUNGYONGHOON.jpg"></div>
                                <div class="profile-cont">
                                    <p>성용훈 SUNG YONG HOON</p>
                                    <ul>
                                        <li>level 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level2-SHINSUNGJAE.jpg"></div>
                                <div class="profile-cont">
                                    <p>신성재 SHIN SUNG JAE</p>
                                    <ul>
                                        <li>level 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level2-JEONHYEONGJOO.jpg"></div>
                                <div class="profile-cont">
                                    <p>전형주 JEON HYEONG JOO</p>
                                    <ul>
                                        <li>level 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level2-JUNGYONGHOON.jpg"></div>
                                <div class="profile-cont">
                                    <p>정용훈 JUNG YONG HOON</p>
                                    <ul>
                                        <li>level 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level2-JOOHANJIN.jpg"></div>
                                <div class="profile-cont">
                                    <p>주한진 JOO HAN JIN</p>
                                    <ul>
                                        <li>level 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115-1/Level2-CHOIJUNWOO.jpg"></div>
                                <div class="profile-cont">
                                    <p>최준우 CHOI JUN WOO</p>
                                    <ul>
                                        <li>level 2</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <!-- SUP 지도자 -->
                    <div class="tab-cont" :class="{ 'active': tabs === 'supLeader' }">
                        <p class="cont-total">인원 <span>18</span> 명</p>
                        <ul class="profiles float-wrap">
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/kimnaeun.jpeg"></div>
                                <div class="profile-cont">
                                    <p>김나은 KIM NAEUN</p>
                                    <ul>
                                        <li>level 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/kimseongju.jpeg"></div>
                                <div class="profile-cont">
                                    <p>김성주 KIM SEONGJU</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/namsuah.jpg"></div>
                                <div class="profile-cont">
                                    <p>남수아 NAM SUAH</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/minkyungshik.jpeg"></div>
                                <div class="profile-cont">
                                    <p>민경식 MIN KYUNGSHIK</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/parksungjun.jpeg"></div>
                                <div class="profile-cont">
                                    <p>박성준 PARK SUNGJUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/anjunyoung.png"></div>
                                <div class="profile-cont">
                                    <p>안준영 AN JUNYOUNG</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/leebonghun.jpeg"></div>
                                <div class="profile-cont">
                                    <p>이봉훈 LEE BONGHUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/joohanjin.jpg"></div>
                                <div class="profile-cont">
                                    <p>주한진 JOO HANJIN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/heojeongsik.jpg"></div>
                                <div class="profile-cont">
                                    <p>허정식 HEO JEONGSIK</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/choijiwon.jpeg"></div>
                                <div class="profile-cont">
                                    <p>최지원 CHOI JIWON</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/ansugon.jpg"></div>
                                <div class="profile-cont">
                                    <p>안수곤 AN SUGON</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/hwangjoowon.jpg"></div>
                                <div class="profile-cont">
                                    <p>황주원 HWANG JOOWON</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/leedongjun.jpg"></div>
                                <div class="profile-cont">
                                    <p>이동준 LEE DONGJUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/parkhyunsu.jpg"></div>
                                <div class="profile-cont">
                                    <p>박현수 PARK HYUNSU</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/KANGTAEMIN.jpg"></div>
                                <div class="profile-cont">
                                    <p>강태민 KANG TAEMIN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/GUDONGLIP.jpg"></div>
                                <div class="profile-cont">
                                    <p>구동립 GU DONGLIP</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/KOWNYOUNGJIN.jpg"></div>
                                <div class="profile-cont">
                                    <p>권영진 KOWN YOUNGJIN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/KIMKYUNGHEE.jpg"></div>
                                <div class="profile-cont">
                                    <p>김경희 KIM KYUNGHEE</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/KIMSANGYOON.jpeg"></div>
                                <div class="profile-cont">
                                    <p>김상윤 KIM SANGYOON</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/Kimahyoung.jpg"></div>
                                <div class="profile-cont">
                                    <p>김아영 KIM AH YOUNG</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/kimchaeeun.jpg"></div>
                                <div class="profile-cont">
                                    <p>김채은 KIM CHAEEUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/KIMTAEHWAN.jpeg"></div>
                                <div class="profile-cont">
                                    <p>김태환 KIM TAEHWAN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/ParkSoojin.jpg"></div>
                                <div class="profile-cont">
                                    <p>1박수진 PARK SOOJIN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/parkjimin.jpg"></div>
                                <div class="profile-cont">
                                    <p>1박지민 PARK JIMIN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/ParkJihwa.jpg"></div>
                                <div class="profile-cont">
                                    <p>박지화 PARK JIHWA</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/Ahnjungmin.jpg"></div>
                                <div class="profile-cont">
                                    <p>안정민 AHN JUNGMIN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/ANJINSUK.jpeg"></div>
                                <div class="profile-cont">
                                    <p>안진석 AN JIN SUK</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/YunJiYoung.jpg"></div>
                                <div class="profile-cont">
                                    <p>윤지영 YUN JI YOUNG</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/LEEDOGEON.jpg"></div>
                                <div class="profile-cont">
                                    <p>이도건 LEE DOGEON</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/LeeSejin.jpg"></div>
                                <div class="profile-cont">
                                    <p>이세진 LEE SEJIN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/LeeSoYoung.jpg"></div>
                                <div class="profile-cont">
                                    <p>이소영 LEE SO YOUNG</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/LEESUJI.jpg"></div>
                                <div class="profile-cont">
                                    <p>이수지 LEE SUJI</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/LeeChangSeob.jpg"></div>
                                <div class="profile-cont">
                                    <p>이창섭 LEE CHANG SEOB</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/LEEHYEYOUNG.jpg"></div>
                                <div class="profile-cont">
                                    <p>이혜영 LEE HYE YOUNG</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li> 
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/Limsujeong111.jpg"></div>
                                <div class="profile-cont">
                                    <p>임수정 LIM SU JEONG</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/ParkJihwa.jpg"></div>
                                <div class="profile-cont">
                                    <p>박지화 PARK JIHWA</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/changtonghun.jpg"></div>
                                <div class="profile-cont">
                                    <p>장동훈CHANG TONGHUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/JEONGSEONGHO.jpeg"></div>
                                <div class="profile-cont">
                                    <p>정성호 JEONG SEONG HO</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/jegalhun.jpg"></div>
                                <div class="profile-cont">
                                    <p>제갈훈 JEGAL HUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/HEOJONGHWAN.jpg"></div>
                                <div class="profile-cont">
                                    <p>허종환 HEO JONGHWAN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/sup-player/HwangInHak.jpeg"></div>
                                <div class="profile-cont">
                                    <p>황인학 HWANG IN HAK</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <!-- 서프레스큐 -->
                    <div class="tab-cont" :class="{ 'active': tabs === 'rescue' }">
                        <p class="cont-total">인원 <span>16</span> 명</p>
                        <ul class="profiles float-wrap">
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/1.kangminseok.jpg"></div>
                                <div class="profile-cont">
                                    <p>강민석 KANG MINSEOK</p>
                                    <ul>
                                        <li>level 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/2.kwonhyuksung.jpg"></div>
                                <div class="profile-cont">
                                    <p>권혁상 KWON HYUKSUNG</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/3.kimnamhun.jpg"></div>
                                <div class="profile-cont">
                                    <p>김남훈 KIM NAMHUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/4.kimminhwan.jpg"></div>
                                <div class="profile-cont">
                                    <p>김민환 KIM MINHWAN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/5.kimbyeongju.jpg"></div>
                                <div class="profile-cont">
                                    <p>김병주 KIM BYEONGJU</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/6.kimjaehwan.jpg"></div>
                                <div class="profile-cont">
                                    <p>김재환 KIM JAEHWAN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/7.kimchaeeun.jpg"></div>
                                <div class="profile-cont">
                                    <p>김채은 KIM CHAEEUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/8.parkgwangho.jpg"></div>
                                <div class="profile-cont">
                                    <p>박광호 PARK GWANGHO</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/9.parksoyoon.jpg"></div>
                                <div class="profile-cont">
                                    <p>박소윤 PARK SOYOON</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/10.parkwoomin.jpg"></div>
                                <div class="profile-cont">
                                    <p>박우민 PARK WOOMIN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/11.sinjonghwa.jpg"></div>
                                <div class="profile-cont">
                                    <p>신종화 SIN JONGHWA</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/12.simmyeongseong.jpg"></div>
                                <div class="profile-cont">
                                    <p>심명성 SIM MYEONGSEONG</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/13.leesukju.jpg"></div>
                                <div class="profile-cont">
                                    <p>이석주 LEE SUKJU</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/14.leesejin.jpg"></div>
                                <div class="profile-cont">
                                    <p>이세진 LEE SEJIN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/15.johansaeng.jpg"></div>
                                <div class="profile-cont">
                                    <p>조한생 JO HANSAENG</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/16.choisungwook.jpg"></div>
                                <div class="profile-cont">
                                    <p>최성욱 CHOI SUNGWOOK</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/KIMSEONGJU.jpg"></div>
                                <div class="profile-cont">
                                    <p>김성주 KIM SEONGJU</p>   
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/JEONSUMIN.jpg"></div>
                                <div class="profile-cont">
                                    <p>전수민 JEON SUMIN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/JANGDONGHYUCK.jpg"></div>
                                <div class="profile-cont">
                                    <p>장동혁 JANG DONGHYUCK</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/CHOIMINSEOK.jpg"></div>
                                <div class="profile-cont">
                                    <p>최민석 CHOI MINSEOK</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/HANSANGJUN.jpg"></div>
                                <div class="profile-cont">
                                    <p>한상준 HAN SANGJUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/KANGMINSEO.jpg"></div>
                                <div class="profile-cont">
                                    <p>강민서 KANG MINSEO</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/surf-rescue/LEESUHYUN.jpg"></div>
                                <div class="profile-cont">
                                    <p>이수현 LEE SUHYUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <!-- <ul class="members members-2 float-wrap">
                            <li>
                                <div class="member-img"><img src="@/assets/images/img/member-1.png"></div>
                                <div class="member-cont">
                                    <p class="member-name">홍길동111</p>
                                    <div class="member-detail">
                                        <p>자격사항</p>
                                        <ul>
                                            <li>3급 윈드서핑 지도자 자격증</li>
                                        </ul>
                                    </div>
                                    <div class="member-sns">
                                        <a href="#"><img src="@/assets/images/icon/icon-fb-line.png" alt="페이스북"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-ig-line.png" alt="인스타그램"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-kakao-line.png" alt="카카오톡"></a>
                                    </div>
                                    <a href="#" class="member-more">더보기 +</a>
                                </div>
                            </li>
                            <li>
                                <div class="member-img"><img src="@/assets/images/img/member-2.png"></div>
                                <div class="member-cont">
                                    <p class="member-name">홍길동</p>
                                    <div class="member-detail">
                                        <p>자격사항</p>
                                        <ul>
                                            <li>3급 윈드서핑 지도자 자격증</li>
                                        </ul>
                                    </div>
                                    <div class="member-sns">
                                        <a href="#"><img src="@/assets/images/icon/icon-fb-line.png" alt="페이스북"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-ig-line.png" alt="인스타그램"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-kakao-line.png" alt="카카오톡"></a>
                                    </div>
                                    <a href="#" class="member-more">더보기 +</a>
                                </div>
                            </li>
                            <li>
                                <div class="member-img"><img src="@/assets/images/img/member-1.png"></div>
                                <div class="member-cont">
                                    <p class="member-name">홍길동</p>
                                    <div class="member-detail">
                                        <p>자격사항</p>
                                        <ul>
                                            <li>3급 윈드서핑 지도자 자격증</li>
                                        </ul>
                                    </div>
                                    <div class="member-sns">
                                        <a href="#"><img src="@/assets/images/icon/icon-fb-line.png" alt="페이스북"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-ig-line.png" alt="인스타그램"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-kakao-line.png" alt="카카오톡"></a>
                                    </div>
                                    <a href="#" class="member-more">더보기 +</a>
                                </div>
                            </li>
                            <li>
                                <div class="member-img"><img src="@/assets/images/img/member-2.png"></div>
                                <div class="member-cont">
                                    <p class="member-name">홍길동</p>
                                    <div class="member-detail">
                                        <p>자격사항</p>
                                        <ul>
                                            <li>3급 윈드서핑 지도자 자격증</li>
                                        </ul>
                                    </div>
                                    <div class="member-sns">
                                        <a href="#"><img src="@/assets/images/icon/icon-fb-line.png" alt="페이스북"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-ig-line.png" alt="인스타그램"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-kakao-line.png" alt="카카오톡"></a>
                                    </div>
                                    <a href="#" class="member-more">더보기 +</a>
                                </div>
                            </li>
                            <li>
                                <div class="member-img"><img src="@/assets/images/img/member-1.png"></div>
                                <div class="member-cont">
                                    <p class="member-name">홍길동</p>
                                    <div class="member-detail">
                                        <p>자격사항</p>
                                        <ul>
                                            <li>3급 윈드서핑 지도자 자격증</li>
                                        </ul>
                                    </div>
                                    <div class="member-sns">
                                        <a href="#"><img src="@/assets/images/icon/icon-fb-line.png" alt="페이스북"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-ig-line.png" alt="인스타그램"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-kakao-line.png" alt="카카오톡"></a>
                                    </div>
                                    <a href="#" class="member-more">더보기 +</a>
                                </div>
                            </li>
                            <li>
                                <div class="member-img"><img src="@/assets/images/img/member-2.png"></div>
                                <div class="member-cont">
                                    <p class="member-name">홍길동</p>
                                    <div class="member-detail">
                                        <p>자격사항</p>
                                        <ul>
                                            <li>3급 윈드서핑 지도자 자격증</li>
                                        </ul>
                                    </div>
                                    <div class="member-sns">
                                        <a href="#"><img src="@/assets/images/icon/icon-fb-line.png" alt="페이스북"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-ig-line.png" alt="인스타그램"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-kakao-line.png" alt="카카오톡"></a>
                                    </div>
                                    <a href="#" class="member-more">더보기 +</a>
                                </div>
                            </li>
                        </ul> -->
                
                    </div>
                    
                    <!-- 심판 -->
                    <div class="tab-cont" :class="{ 'active': tabs === 'referee' }">
                        <p class="cont-total">인원 <span>35</span> 명</p>
                        <ul class="profiles float-wrap">
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/namsuah.jpg"></div>
                                <div class="profile-cont">
                                    <p>남수아 NAM SUAH</p>
                                    <ul>
                                        <li>level 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/limsujeong.jpg"></div>
                                <div class="profile-cont">
                                    <p>임수정 LIM SUJEONG</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/changtonghun.jpg"></div>
                                <div class="profile-cont">
                                    <p>장동훈 CHANG TONG HUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/baekseojun.jpg"></div>
                                <div class="profile-cont">
                                    <p>백서준 BAEK SEO JUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/cheonmyeongki.png"></div>
                                <div class="profile-cont">
                                    <p>천명기 CHEON MYEONG KI</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/parkhyoeun.jpg"></div>
                                <div class="profile-cont">
                                    <p>박효은 PARK HYOE UN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/jobomyi.jpg"></div>
                                <div class="profile-cont">
                                    <p>조봄이 JO BOM YI</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/jungkeunyoung.jpg"></div>
                                <div class="profile-cont">
                                    <p>정근영 JUNG KEUN YOUNG</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/kimyonghun.jpg"></div>
                                <div class="profile-cont">
                                    <p>김용훈 KIM YONG HUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/hwanghyejin.jpg"></div>
                                <div class="profile-cont">
                                    <p>황혜진 HWANG HYE JIN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/kimjin.jpg"></div>
                                <div class="profile-cont">
                                    <p>김 진 KIM JIN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/jeonhyeongjoo.jpg"></div>
                                <div class="profile-cont">
                                    <p>전형주 JEON HYEONG JOO</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/kimjihun.jpg"></div>
                                <div class="profile-cont">
                                    <p>김지훈 KIM JI HUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/kimdaekyoung.jpg"></div>
                                <div class="profile-cont">
                                    <p>김대경 KIM DAEK YOUNG</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/hadongwoo.jpg"></div>
                                <div class="profile-cont">
                                    <p>하동우 HA DONG WOO</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/parkwanwung.jpg"></div>
                                <div class="profile-cont">
                                    <p>박완웅 PARK WAN WUNG</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/kimjieun.jpg"></div>
                                <div class="profile-cont">
                                    <p>김지은 KIM JI EUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player/parkjunhyung.png"></div>
                                <div class="profile-cont">
                                    <p>박준형 PARK JUN HYUNG</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115/Level1-KWONKYOUNGMIN.jpg"></div>
                                <div class="profile-cont">
                                    <p>권경민 KWON KYOUNG MIN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115/Level1-KIMCHAEEUN.jpg"></div>
                                <div class="profile-cont">
                                    <p>김채은 KIM CHAE EUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115/Level1-MUNSEOKJIN.jpg"></div>
                                <div class="profile-cont">
                                    <p>문석진 MUN SEOK JIN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115/Level1-PARKJEUNGJONG.jpg"></div>
                                <div class="profile-cont">
                                    <p>박정종 PARK JEUNG JONG</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115/Level1-BAEBYUNGHONG.jpg"></div>
                                <div class="profile-cont">
                                    <p>배병홍 BAE BYUNG HONG</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115/Level1-SHINSUNRJAE.jpg"></div>
                                <div class="profile-cont">
                                    <p>신성재 SHIN SUNG JAE</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115/Level1-LEEWONTAEK.jpg"></div>
                                <div class="profile-cont">
                                    <p>이원택 LEE WON TAEK</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115/Level1-JUNGYONGHOON.jpg"></div>
                                <div class="profile-cont">
                                    <p>정용훈 JUNG YONG HOON</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115/Level1-JOOKWANGCHOUN.jpg"></div>
                                <div class="profile-cont">
                                    <p>주광춘 JOO KWANG CHOUN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115/Level1-CHOIJAEYOUNG.jpg"></div>
                                <div class="profile-cont">
                                    <p>최재영 CHOI JAE YOUNG</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115/Level1-HANBYUNGCHAE.jpg"></div>
                                <div class="profile-cont">
                                    <p>한병채 HAN BYUNG CHAE</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115/Level1-HONGSUOK.jpg"></div>
                                <div class="profile-cont">
                                    <p>홍수옥HONG SU OK</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115/Level2-PARKSUNGJUN.jpeg"></div>
                                <div class="profile-cont">
                                    <p>박성준 PARK SUNG JUN</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115/Level2-JOOHANJIN.jpg"></div>
                                <div class="profile-cont">
                                    <p>주한진 JOO HAN JIN</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115/Level3-MINKYUNGSHIK.jpeg"></div>
                                <div class="profile-cont">
                                    <p>민경식 MIN KYUNG SHIK</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115/Level3-SONGMIN.jpg"></div>
                                <div class="profile-cont">
                                    <p>송민 SONG MIN</p>
                                    <ul>
                                        <li>LEVEL 2</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/player20240115/Level1-KIMBOYOUNG.png"></div>
                                <div class="profile-cont">
                                    <p>김보영 KIM BOYOUNG</p>
                                    <ul>
                                        <li>LEVEL 1</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>


                        <!-- <ul class="members members-2 float-wrap">
                            <li>
                                <div class="member-img"><img src="@/assets/images/img/member-1.png"></div>
                                <div class="member-cont">
                                    <p class="member-name">홍길동</p>
                                    <div class="member-detail">
                                        <p>자격사항</p>
                                        <ul>
                                            <li>3급 윈드서핑 지도자 자격증</li>
                                        </ul>
                                    </div>
                                    <div class="member-sns">
                                        <a href="#"><img src="@/assets/images/icon/icon-fb-line.png" alt="페이스북"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-ig-line.png" alt="인스타그램"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-kakao-line.png" alt="카카오톡"></a>
                                    </div>
                                    <a href="#" class="member-more">더보기 +</a>
                                </div>
                            </li>
                            <li>
                                <div class="member-img"><img src="@/assets/images/img/member-2.png"></div>
                                <div class="member-cont">
                                    <p class="member-name">홍길동</p>
                                    <div class="member-detail">
                                        <p>자격사항</p>
                                        <ul>
                                            <li>3급 윈드서핑 지도자 자격증</li>
                                        </ul>
                                    </div>
                                    <div class="member-sns">
                                        <a href="#"><img src="@/assets/images/icon/icon-fb-line.png" alt="페이스북"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-ig-line.png" alt="인스타그램"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-kakao-line.png" alt="카카오톡"></a>
                                    </div>
                                    <a href="#" class="member-more">더보기 +</a>
                                </div>
                            </li>
                            <li>
                                <div class="member-img"><img src="@/assets/images/img/member-1.png"></div>
                                <div class="member-cont">
                                    <p class="member-name">홍길동</p>
                                    <div class="member-detail">
                                        <p>자격사항</p>
                                        <ul>
                                            <li>3급 윈드서핑 지도자 자격증</li>
                                        </ul>
                                    </div>
                                    <div class="member-sns">
                                        <a href="#"><img src="@/assets/images/icon/icon-fb-line.png" alt="페이스북"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-ig-line.png" alt="인스타그램"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-kakao-line.png" alt="카카오톡"></a>
                                    </div>
                                    <a href="#" class="member-more">더보기 +</a>
                                </div>
                            </li>
                            <li>
                                <div class="member-img"><img src="@/assets/images/img/member-2.png"></div>
                                <div class="member-cont">
                                    <p class="member-name">홍길동</p>
                                    <div class="member-detail">
                                        <p>자격사항</p>
                                        <ul>
                                            <li>3급 윈드서핑 지도자 자격증</li>
                                        </ul>
                                    </div>
                                    <div class="member-sns">
                                        <a href="#"><img src="@/assets/images/icon/icon-fb-line.png" alt="페이스북"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-ig-line.png" alt="인스타그램"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-kakao-line.png" alt="카카오톡"></a>
                                    </div>
                                    <a href="#" class="member-more">더보기 +</a>
                                </div>
                            </li>
                            <li>
                                <div class="member-img"><img src="@/assets/images/img/member-1.png"></div>
                                <div class="member-cont">
                                    <p class="member-name">홍길동</p>
                                    <div class="member-detail">
                                        <p>자격사항</p>
                                        <ul>
                                            <li>3급 윈드서핑 지도자 자격증</li>
                                        </ul>
                                    </div>
                                    <div class="member-sns">
                                        <a href="#"><img src="@/assets/images/icon/icon-fb-line.png" alt="페이스북"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-ig-line.png" alt="인스타그램"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-kakao-line.png" alt="카카오톡"></a>
                                    </div>
                                    <a href="#" class="member-more">더보기 +</a>
                                </div>
                            </li>
                            <li>
                                <div class="member-img"><img src="@/assets/images/img/member-2.png"></div>
                                <div class="member-cont">
                                    <p class="member-name">홍길동</p>
                                    <div class="member-detail">
                                        <p>자격사항</p>
                                        <ul>
                                            <li>3급 윈드서핑 지도자 자격증</li>
                                        </ul>
                                    </div>
                                    <div class="member-sns">
                                        <a href="#"><img src="@/assets/images/icon/icon-fb-line.png" alt="페이스북"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-ig-line.png" alt="인스타그램"></a>
                                        <a href="#"><img src="@/assets/images/icon/icon-kakao-line.png" alt="카카오톡"></a>
                                    </div>
                                    <a href="#" class="member-more">더보기 +</a>
                                </div>
                            </li>
                        </ul> -->
                
                    </div>

                    <!-- 서핑지도자 -->
                    <div class="tab-cont" :class="{ 'active': tabs === 'player' }">
                        <p class="cont-total">인원 <span>124</span> 명</p>
                         <ul class="profiles float-wrap">
                           
                        </ul>
                        <!-- <ul class="profiles float-wrap">
                            <li>
                                <div class="profile-img"><img src="@/assets/images/img/player-1.png"></div>
                                <div class="profile-cont">
                                    <p>홍길동 GilDong Hong</p>
                                    <ul>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>대한민국 서핑 롱보드 국가대표 / KSL Pro Surfer</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/img/player-2.png"></div>
                                <div class="profile-cont">
                                    <p>홍길동 GilDong Hong</p>
                                    <ul>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>대한민국 서핑 롱보드 국가대표 / KSL Pro Surfer</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/img/player-3.png"></div>
                                <div class="profile-cont">
                                    <p>홍길동 GilDong Hong</p>
                                    <ul>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>대한민국 서핑 롱보드 국가대표 / KSL Pro Surfer</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/img/player-4.png"></div>
                                <div class="profile-cont">
                                    <p>홍길동 GilDong Hong</p>
                                    <ul>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>대한민국 서핑 롱보드 국가대표 / KSL Pro Surfer</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/img/player-5.png"></div>
                                <div class="profile-cont">
                                    <p>홍길동 GilDong Hong</p>
                                    <ul>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>대한민국 서핑 롱보드 국가대표 / KSL Pro Surfer</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/img/player-1.png"></div>
                                <div class="profile-cont">
                                    <p>홍길동 GilDong Hong</p>
                                    <ul>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>대한민국 서핑 롱보드 국가대표 / KSL Pro Surfer</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/img/player-2.png"></div>
                                <div class="profile-cont">
                                    <p>홍길동 GilDong Hong</p>
                                    <ul>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>대한민국 서핑 롱보드 국가대표 / KSL Pro Surfer</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/img/player-3.png"></div>
                                <div class="profile-cont">
                                    <p>홍길동 GilDong Hong</p>
                                    <ul>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>대한민국 서핑 롱보드 국가대표 / KSL Pro Surfer</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/img/player-4.png"></div>
                                <div class="profile-cont">
                                    <p>홍길동 GilDong Hong</p>
                                    <ul>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>대한민국 서핑 롱보드 국가대표 / KSL Pro Surfer</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/img/player-5.png"></div>
                                <div class="profile-cont">
                                    <p>홍길동 GilDong Hong</p>
                                    <ul>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>대한민국 서핑 롱보드 국가대표 / KSL Pro Surfer</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/img/player-1.png"></div>
                                <div class="profile-cont">
                                    <p>홍길동 GilDong Hong</p>
                                    <ul>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>대한민국 서핑 롱보드 국가대표 / KSL Pro Surfer</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="profile-img"><img src="@/assets/images/img/player-5.png"></div>
                                <div class="profile-cont">
                                    <p>홍길동 GilDong Hong</p>
                                    <ul>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>2021 KSL Longboard Champ</li>
                                        <li>대한민국 서핑 롱보드 국가대표 / KSL Pro Surfer</li>
                                    </ul>
                                </div>
                            </li>
                        </ul> -->
                    </div>

                </div>
            </div>
        </div>  
    </div>
</template>

<script setup>
    import { ref } from 'vue'

    const tabs = ref('leader')
    // const activeTabId = ref(1)

    // const setTab = (tabId) => {
    //     activeTabId.value = tabId
    // }  
</script>

<style>

</style>